import Vue from 'vue'
import VueRouter from 'vue-router'
import About from '../views/About.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/skills',
    name: 'skills',
    component: () => import('../views/Skills.vue')
  },
  {
    path: '/experience',
    name: 'experience',
    component: () => import('../views/Experience.vue')
  },
  {
    path: '/awards',
    name: 'awards',
    component: () => import('../views/Awards.vue')
  },
  {
    path: '/samples',
    name: 'samples',
    component: () => import('../views/Samples.vue')
  },
  {
    path: '*',
    component: About
  },
]

const router = new VueRouter({
  routes
})

export default router
