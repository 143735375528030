<template>
    <b-container class="container-md mt-5">
        <b-row>
            <b-col>
                <h3>Hello, I am Sanghun Kim</h3>
            </b-col>
        </b-row>
        <b-row class="aboutBody">
            <b-col class="text-start p-50 col-12 col-lg-6">
                <p>
                    I am a front end developer with 25+ years experience with creative background, and recently moved to
                    Northern Virginia from NJ...
                </p>
                <p>
                    My core competencies include translating business needs and technical requirements into reusable content
                    templates, testing and optimizing web-based training and interactive media, developing and managing
                    cross-browser responsive and mobile web applications with Vue.js, JavaScript, HTML, CSS, and Cordova,
                    and contributing to UX/UI design for new eLearning, games, and visual aid tools. I have also been
                    actively involved in the software development life cycle, using Agile methodologies and ensuring
                    accountability for the team's work during the code review and approval process. My mission is to deliver
                    high-quality and engaging learning solutions that enhance customer satisfaction and meet their
                    educational and business objectives.
                </p>
            </b-col>
            <b-col class="selfPortrait p-50 col-12 col-lg-6">
                <img src="@/assets/self.jpg" class="img-thumbnail">
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

export default {
    name: 'AboutView'
}
</script>
<style lang="less">
.p-50 {
    padding: 50px;

    @media screen and (max-width: 991px) {
        padding: 20px 50px;
    }
}

.aboutBody {
    @media screen and (max-width: 991px) {
        flex-direction: column-reverse;
    }
}

.selfPortrait {
    text-align: center;

    img {
        -webkit-filter: grayscale(100%);
        /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
}

.experience {
    .company {
        font-size: 1.1em;
        font-weight: bold;
        margin-bottom: 0;
    }

    .title {
        font-style: italic;
    }
}
</style>