<template>
  <b-navbar toggleable="lg" type="light" :sticky="true">
    <b-navbar-toggle target="nav-collapse" class="me-2 ms-auto"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{ name: 'about' }">About</b-nav-item>
        <b-nav-item :to="{ name: 'skills' }">Skills</b-nav-item>
        <b-nav-item :to="{ name: 'experience' }">Experience</b-nav-item>
        <b-nav-item :to="{ name: 'awards' }">Awards</b-nav-item>
        <b-nav-item :to="{ name: 'samples' }">Samples</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ms-auto">
        <b-link href="https://www.linkedin.com/in/sanghun-kim-8133202/" target="linkedIn">
          <b-icon icon="linkedin" class="me-3"></b-icon>
        </b-link>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: 'TopNav'
}
</script>

<style scoped lang="less">
.navbar {
  background: #f5f5f5;
  border-bottom: 1px solid #ccc;

  .router-link-active {
    font-weight: bold;
  }
}
</style>
